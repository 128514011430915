exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aiuti-js": () => import("./../../../src/pages/aiuti.js" /* webpackChunkName: "component---src-pages-aiuti-js" */),
  "component---src-pages-call-js": () => import("./../../../src/pages/call.js" /* webpackChunkName: "component---src-pages-call-js" */),
  "component---src-pages-cap-js": () => import("./../../../src/pages/cap.js" /* webpackChunkName: "component---src-pages-cap-js" */),
  "component---src-pages-cap-yob-js": () => import("./../../../src/pages/cap-yob.js" /* webpackChunkName: "component---src-pages-cap-yob-js" */),
  "component---src-pages-chetariffa-quiz-js": () => import("./../../../src/pages/chetariffa/quiz.js" /* webpackChunkName: "component---src-pages-chetariffa-quiz-js" */),
  "component---src-pages-dtc-offerte-internet-casa-js": () => import("./../../../src/pages/dtc/offerte-internet-casa.js" /* webpackChunkName: "component---src-pages-dtc-offerte-internet-casa-js" */),
  "component---src-pages-gsm-luce-e-gas-js": () => import("./../../../src/pages/gsm/luce-e-gas.js" /* webpackChunkName: "component---src-pages-gsm-luce-e-gas-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jln-luce-e-gas-js": () => import("./../../../src/pages/jln/luce-e-gas.js" /* webpackChunkName: "component---src-pages-jln-luce-e-gas-js" */),
  "component---src-pages-jln-offerte-internet-casa-js": () => import("./../../../src/pages/jln/offerte-internet-casa.js" /* webpackChunkName: "component---src-pages-jln-offerte-internet-casa-js" */),
  "component---src-pages-luce-e-gas-js": () => import("./../../../src/pages/luce-e-gas.js" /* webpackChunkName: "component---src-pages-luce-e-gas-js" */),
  "component---src-pages-luce-e-gas-moc-js": () => import("./../../../src/pages/luce-e-gas-moc.js" /* webpackChunkName: "component---src-pages-luce-e-gas-moc-js" */),
  "component---src-pages-meglioquesto-quiz-js": () => import("./../../../src/pages/meglioquesto/quiz.js" /* webpackChunkName: "component---src-pages-meglioquesto-quiz-js" */),
  "component---src-pages-offerte-internet-casa-js": () => import("./../../../src/pages/offerte-internet-casa.js" /* webpackChunkName: "component---src-pages-offerte-internet-casa-js" */),
  "component---src-pages-supermoney-quiz-js": () => import("./../../../src/pages/supermoney/quiz.js" /* webpackChunkName: "component---src-pages-supermoney-quiz-js" */),
  "component---src-pages-thank-you-aiuti-js": () => import("./../../../src/pages/thank-you-aiuti.js" /* webpackChunkName: "component---src-pages-thank-you-aiuti-js" */),
  "component---src-pages-thank-you-gsm-js": () => import("./../../../src/pages/thank-you-gsm.js" /* webpackChunkName: "component---src-pages-thank-you-gsm-js" */),
  "component---src-pages-thank-you-jln-js": () => import("./../../../src/pages/thank-you-jln.js" /* webpackChunkName: "component---src-pages-thank-you-jln-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-voucher-tv-js": () => import("./../../../src/pages/voucher-tv.js" /* webpackChunkName: "component---src-pages-voucher-tv-js" */),
  "component---src-pages-wc-quiz-js": () => import("./../../../src/pages/wc/quiz.js" /* webpackChunkName: "component---src-pages-wc-quiz-js" */)
}

